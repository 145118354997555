/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { publicProfileActions } from '../Actions';
import { Helmet } from 'react-helmet';
import UnclaimedProfileConatiner from '../Containers/UnclaimedProfileContainer';

const UnclaimedPageRouter: React.FC = (): JSX.Element => {
  const { path } = useRouteMatch();
  console.log(path);
  const user = useParams() as { username: string };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(publicProfileActions.getPublicProfile(user.username));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <title>{user.username} | Rehustle - Sell and Grow with ease</title>
      </Helmet>
      <Switch>
        <Route exact path={path} children={<UnclaimedProfileConatiner />} />
      </Switch>
    </>
  );
};

export default UnclaimedPageRouter;
