export const APP_ROUTES = {
  MAIN: '/',
  LOGIN: '/login',
  DASHBOARD: '/home',
  SIGNUP: '/register',
  PROFILE: '/app/profile',
  ABOUT: '/app/about',
  LINKS: '/links',
  ADD_SERVICES: '/app/select-service',
  ADD_TEMPLATE: '/app/choose-template',
  ONBOARDING: '/app/onboarding',
  SERVICE: '/app/service',
  INSIGHT: '/app/stats',
  PAYOUTS: '/app/payouts',
  PREVIEW: '/app/preview',
  HELP: '/help',
  NOTFOUND: '/not-found',
  THANKYOU: '/thank-you',
  RESET_PASSWORD: '/reset-password',
  RESET: '/reset',
  USERNAME: '/:username',
  ABOUTUS: '/about-us',
  PRIVACYPOLICY: '/privacy-policy',
  TERMSANDCONDITIONS: '/terms-conditions',
  REFUNDS: '/refunds',
  CONTACTUS: '/contact-us',
  IMPORT: '/app/import',
  SETTINGS: '/app/settings',
};

export const APP_CONSTANTS = {
  PROFILE_DOMAIN: process.env.REACT_APP_REHUSTLE_PROFILE_DOMAIN || 'https://rehustle.netlify.app',
  PROFILE_HOST_DOMAIN: process.env.REHUSTLE_PROFILE_HOST_DOMAIN || 'rehustle.co',
};
