import React from 'react';
export default function Faqs(): JSX.Element {
  return (
    <>
      <div className="bg-white">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6 text-3xl font-extrabold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-gray-7 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                  <defs>
                    <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".135" height=".30">
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24" />
                </svg>
                <span className="relative">Questions?</span>
              </span>{' '}
            </h2>
            <p className="text-xl text-gray-500">
              Find commonly asked questions below. If you have further questions, write an email at hello@rehustle.co
            </p>
          </div>
          <div className="max-w-screen-xl sm:mx-auto">
            <div className="grid grid-cols-1 gap-16 row-gap-8 lg:grid-cols-2">
              <div className="space-y-10">
                <div>
                  <p className="mb-4 text-lg font-medium">How do I get paid?</p>
                  <p className="text-gray-3">
                    You need to update your Bank Account details in the Account settings to get paid. Payouts are
                    processed within 48 hours of receiving the payment from your customers.
                  </p>
                </div>
                <hr />
                <div>
                  <p className="mb-4 text-lg font-medium">Why should I use ReHustle?</p>
                  <p className="text-gray-3">
                    If you are one of the creators who want to monetize their products or services from clicks of your
                    mobile phone, get a single profile link and get paid easily, ReHustle is the perfect choice.
                  </p>
                </div>
                <hr />
                <div>
                  <p className="mb-4 text-lg font-medium">What do I need to start using ReHustle?</p>
                  <p className="text-gray-3">
                    You just need your email address to get started with ReHustle. Afterwards, complete your profile,
                    add services and share your profile link to get paid.
                  </p>
                </div>
                <hr />
              </div>
              <div className="space-y-10">
                <div>
                  <p className="mb-4 text-lg font-medium">How can my audience pay?</p>
                  <p className="text-gray-3">
                    We accept all major Credit Cards, Debit Cards, Wallets, UPI, Net banking etc. to process payments on
                    your behalf using Paytm Payment Gateway.
                  </p>
                </div>
                <hr />
                <div>
                  <p className="mb-4 text-lg font-medium">Can I customize my Profile link?</p>
                  <p className="text-gray-3">
                    You can login to your account and visit the Profile section to customize the username and use the
                    updated profile link to share with your audience and customers.
                  </p>
                </div>
                <hr />
                <div>
                  <p className="mb-4 text-lg font-medium">How can I check my profile views and payments received?</p>
                  <p className="text-gray-3">
                    You can login to your account and visit the Stats section to check the analytics for your account.
                    Moreover, you will get real time alerts for the payments on your registered email.
                  </p>
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
