/* eslint-disable react/no-children-prop */
import React, { useEffect, lazy, Suspense } from 'react';
import { Router, Route, Switch, Redirect, RouteChildrenProps, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ROUTES } from '../Utils/routeConstant';
import { InitialStore } from '../helpers/initialState';
import { history } from '../helpers/history';
import { Toaster } from 'react-hot-toast';
import Loader from '../Components/Loader';
import LandingPageComp from '../LandingPage/LandingPage';
import NotFound from '../NotFound/NotFound';
import { toastOptions } from './Utils/toasterOptions';
import ResetPasswordContainer from '../Containers/ResetPasswordContainer';
import { UserOnboardingStage } from '../Utils/UserOnboardingStage';
import ErrorBoundary from '../Components/ErrorBoundary';
import { Helmet } from 'react-helmet';
import { userProfileActions } from '../Actions';
import StaticPage from '../StaticPages/StaticPageContainer';
import ScrollToTop from '../Components/ScrollToTop';
import PublicPageRouter from './PublicRoutes';
import UnclaimedPageRouter from './UnclaimedRoutes';
const PrivateRoute = lazy(() => import('../Components/PrivateRoute'));
const ThankYouPage = lazy(() => import('../Containers/ThankYouPage'));
const PreLoginContainer = lazy(() => import('../Containers/PreLoginConatiner'));
const PostLoginContainer = lazy(() => import('../Containers/PostLoginConatiner'));

declare const window: any; /* eslint-disable-line  @typescript-eslint/no-explicit-any */

const SwitchOptions: React.FC = (): JSX.Element => {
  const location = useLocation();
  const routePath = Object.values(APP_ROUTES);
  const isProductRoute = routePath.includes(location.pathname);
  const checkToken = (): boolean => !!localStorage.getItem('token');
  const isTokenAvailable = checkToken();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isTokenAvailable && isProductRoute) {
      dispatch(userProfileActions.getCurentUser());
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  useEffect(() => {
    if (process.env.API_ENV === 'production') {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      console.log = function () {};
      //safe check
      if (typeof window !== 'undefined') {
        window.gtag('config', 'G-VWTW9XE70Y', { page_path: window.location.pathname + window.location.search });
      }
    }
  });

  const redirects = (props: RouteChildrenProps) => {
    if (isTokenAvailable && userProfile?.setupStage === UserOnboardingStage.REGISTRATION_DONE) {
      return <Redirect to={{ pathname: APP_ROUTES.IMPORT, state: { from: props.location } }} />;
    } else if (isTokenAvailable && userProfile?.setupStage === UserOnboardingStage.PROFILE_FETCH_DONE) {
      return <Redirect to={{ pathname: APP_ROUTES.ABOUT, state: { from: props.location } }} />;
    } else if (isTokenAvailable && userProfile?.setupStage === UserOnboardingStage.ABOUT_PAGE_DONE) {
      return <Redirect to={{ pathname: APP_ROUTES.ADD_SERVICES, state: { from: props.location } }} />;
    } else if (isTokenAvailable && userProfile?.setupStage === UserOnboardingStage.SERVICE_PAGE_DONE) {
      return <Redirect to={{ pathname: APP_ROUTES.ONBOARDING, state: { from: props.location } }} />;
    } else if (isTokenAvailable && userProfile?.setupStage === UserOnboardingStage.ONBOARDING_DONE) {
      return <Redirect to={{ pathname: APP_ROUTES.SERVICE, state: { from: props.location } }} />;
    } else {
      return <Redirect to={{ pathname: APP_ROUTES.DASHBOARD, state: { from: props.location } }} />;
    }
  };
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route exact={true} path={APP_ROUTES.MAIN} component={LandingPageComp} />
        <Route exact={true} path={APP_ROUTES.ABOUTUS} children={<StaticPage childComp={'AboutUsPage'} />} />
        <Route
          exact={true}
          path={APP_ROUTES.TERMSANDCONDITIONS}
          children={<StaticPage childComp={'TermsConditionPage'} />}
        />
        <Route exact={true} path={APP_ROUTES.PRIVACYPOLICY} children={<StaticPage childComp={'PrivacyPolicyPage'} />} />
        <Route exact={true} path={APP_ROUTES.REFUNDS} children={<StaticPage childComp={'RefundsPage'} />} />
        <Route exact={true} path={APP_ROUTES.CONTACTUS} children={<StaticPage childComp={'ContactUsPage'} />} />

        <Route path={APP_ROUTES.LOGIN} exact={true} children={<PreLoginContainer childComp={'LoginPage'} />} />
        <Route
          path={APP_ROUTES.SIGNUP}
          exact={true}
          children={<PreLoginContainer childComp={'RegisterPage'} onboarding={'17%'} title={'Create your account'} />}
        />
        <Route path={APP_ROUTES.RESET} exact={true} children={() => <PostLoginContainer childComp={'ResetPage'} />} />
        <Route exact={true} path={APP_ROUTES.RESET_PASSWORD} children={() => <ResetPasswordContainer />} />
        <Route exact={true} path={APP_ROUTES.DASHBOARD} children={(props) => redirects(props)} />
        <Route
          exact={true}
          path={'/app/:id'}
          children={() => <PrivateRoute setupStage={userProfile?.setupStage} isTokenAvailable={isTokenAvailable} />}
        />
        <Route exact={true} path={APP_ROUTES.THANKYOU} component={ThankYouPage} />
        <Route path={'/unclaimed/:username'} component={UnclaimedPageRouter} />
        <Route path={APP_ROUTES.NOTFOUND} component={NotFound} />
        <Route path={APP_ROUTES.USERNAME} render={() => <PublicPageRouter />} />
      </Switch>
    </>
  );
};
function App(): JSX.Element {
  return (
    <>
      <Helmet>
        <title>Rehustle - Sell and Grow with ease</title>
        <meta
          name="description"
          content="Share your Content, Social links, Services and get paid easily via Paytm - with just one Profile
      link"
        />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <ErrorBoundary>
        <div>
          <div>
            <Toaster toastOptions={toastOptions} />
          </div>
          <Router history={history}>
            <Suspense fallback={<Loader />}>
              <SwitchOptions />
            </Suspense>
          </Router>
        </div>
      </ErrorBoundary>
    </>
  );
}

export default App;
